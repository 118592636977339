import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

export default function FreeSoloCreateOptionDialog(props) {
  const {
    allUsers,
    setNewIntervenant,
    newIntervenant,
    setIntervenants,
    intervenants,
    editIndex,
    setWrongInput
  } = props;
  const [value, setValue] = React.useState(null);

  return (
    <React.Fragment>
      <Autocomplete
        value={
          editIndex !== undefined
            ? {
                id: intervenants[editIndex].userId,
                name: intervenants[editIndex].prenomIntervenant,
                surname: intervenants[editIndex].nomIntervenant,
              }
            : value
        }
        onChange={(event, newValue) => {
            console.log(newValue);
       setWrongInput((wrong) => wrong - 1);
          if (newValue !== null) {
            if (newIntervenant) {
              setNewIntervenant({
                ...newIntervenant,
                id: newValue.id,
                prenomIntervenant: newValue.name,
                nomIntervenant: newValue.surname,
              fileStatus : newValue.fileStatus
              });
              setValue(newValue);
            } else {
              const updatedIntervenants = [...intervenants];
              updatedIntervenants[editIndex].prenomIntervenant = newValue.name;
              updatedIntervenants[editIndex].nomIntervenant = newValue.surname;
                updatedIntervenants[editIndex].fileStatus = newValue.fileStatus;
                updatedIntervenants[editIndex].userId = newValue.id;
              setIntervenants(updatedIntervenants);
            }
          }
        }}
        noOptionsText={"Pas d'utilisateur trouvé, ajoutez le !"}
        options={allUsers.sort((a, b) => a.type.localeCompare(b.type))}
        groupBy={(option) => option.type}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return `${option.surname} ${option.name}`;
        }}
        // filterOptions={filterOptions}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <Typography
              key={option.id}
              {...optionProps} /*sx={{ fontWeight: 'bold' }}*/
            >
              {option.surname} {option.name}
              {/*<Typography component="span" sx={{ color: 'grey', marginLeft: '5px' }}>*/}
              {/*    {option.type}*/}
              {/*</Typography>*/}
            </Typography>
          );
        }}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Nom et prénom de l'intervenant" />
        )}
      />
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Box,
  Button, Select, MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import FreeSoloCreateOptionDialog from "../FreeSoloCreateOptionDialog";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { DialogAddIntervenant } from "../../Shared/DialogAddIntervenant";
import { fetchActiveUsers } from "../../Shared/CallAPI/Fetch/fetchActiveUsers";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import dayjs from "dayjs";
import { addIntervention } from "../api_requests/addIntervention";
import toast, { Toaster } from "react-hot-toast";
import { modifyIntervention } from "../api_requests/modifyIntervention";
import { deleteIntervention } from "../api_requests/deleteIntervention";
import {updateEqTD} from "../api_requests/updateEqTD";

export default function IntervenantsTableCollectif(props) {
  const {
    allUsers,
    intervenants,
    setIntervenants,
    newIntervenant,
    setNewIntervenant,
    types,
    roles,  
    setAllUsers,
    totalIsTooHight,
    ficheId,
    annee,
    disableAddInterv,
    dateMax,
    coefA,
    coefB,
    coefC,
    domaines,
    totalEqTD
  } = props;
  const [editIndex, setEditIndex] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const currentDate = dayjs().format("DD/MM/YYYY");
  const [tooltipText, setTooltipText] = useState("Sauvegarder");
  const [intervenantEdited, setIntervenantEdited] = useState([]);

  const isDateAfterMax = dayjs().isAfter(dayjs(dateMax).add(1, 'day'));
  const isDirector =  JSON.parse(localStorage.getItem("user")).role.id === 1;
  const [wrongInput, setWrongInput] = useState(2);
  
  useEffect(() => {
    setTooltipText(
      !totalIsTooHight ? "Sauvegarder" : "Le total dépasse le budget",
    );
    
  }, [totalIsTooHight]);

  const handleAddIntervenant = () => {
    setNewIntervenant({
      nomIntervenant: "",
      prenomIntervenant: "",
      cm: 0,
      td: 0,
      tp: 0,
      totalEqTD: 0,
      idDomaine: "",
      anneeScolaire: annee,
    });
    setIsEditing(true);
  };

  const openAddIntervenant = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const newUserReload = () => {
    fetchActiveUsers()
      .then((user) => setAllUsers(user))
      .catch((error) => console.log(error));
  };

  const handleEditIntervenant = (index) => {
    const intervenantCopy = JSON.parse(JSON.stringify(intervenants[index]));
    setIntervenantEdited(intervenantCopy);
    setEditIndex(index);
    setIsEditing(true);
  };

  const saveInTable = () => {
    let newFiche = {
      id: ficheId,
      totalEqTD: totalEqTD
    }
    if (!totalIsTooHight || isDirector) {
      if (newIntervenant) {
        //serialization of newIntervenant
        const newIntervenantWithInfo = {
          userId: newIntervenant.id,
          ficheId: ficheId,
          cm: newIntervenant.cm,
          td: newIntervenant.td,
          tp: newIntervenant.tp,
          totalEqTD: newIntervenant.totalEqTD,
          idDomaine: newIntervenant.domaine,
          anneeScolaire: annee,
          fileStatus : newIntervenant.fileStatus
        };
        newIntervenant.domaine = {
          id : newIntervenant.domaine
        }
        const promise = addIntervention(newIntervenantWithInfo).then((msg) => {
          newIntervenant.id = msg.data.id;
          setIntervenants([...intervenants, newIntervenant]);
          setNewIntervenant(null);
          setIsEditing(false);
          newFiche.totalEqTd += newIntervenant.totalEqTD;
          // updateEqTD(newFiche);
          return msg.message;
        });

        toast.promise(promise, {
          loading: "Ajout de l'intervenant...",
          success: (msg) => <b>{msg}</b>,
          error: (msg) => <b>{msg}</b>,
        });
        updateEqTD(newFiche);
        setIsEditing(false);
        setNewIntervenant(null);
      } else {
        const UpdatedIntervenant = {
          id: intervenants[editIndex].id,
          userId: intervenants[editIndex].userId,
          cm: intervenants[editIndex].cm,
          td: intervenants[editIndex].td,
          tp: intervenants[editIndex].tp,
          anneeScolaire: annee,
          idDomaine: intervenants[editIndex].domaine.id,
          totalEqTD : intervenants[editIndex].totalEqTD,
          fileStatus : intervenants[editIndex].fileStatus
        };
        const promise = modifyIntervention(UpdatedIntervenant).then((msg) => {
          setNewIntervenant(null);
          setIsEditing(false);
          newFiche.totalEqTd += intervenants[editIndex].totalEqTD;
          return msg;
        });

        toast.promise(promise, {
          loading: "Modification de l'intervenant...",
          success: (msg) => <b>{msg}</b>,
          error: (msg) => <b>{msg}</b>,
        });
        //call API update Intervenant .then()
        updateEqTD(newFiche);
        setEditIndex(null);
        setIsEditing(false);
        
      }
      //setBeforeChanges(intervenants);
    }
    setWrongInput(2);
  };

  const handleDeleteIntervenant = (index) => {
    const deleteIntervenants = [...intervenants];
    deleteIntervenants.splice(index, 1);
    const promise = deleteIntervention(intervenants[index].id).then(
      (msg) => {
        setIntervenants(deleteIntervenants);
        return msg;
      },);
      let newFiche = {
        id: ficheId,
        totalEqTD: totalEqTD - intervenants[index].totalEqTD
      }
      toast.promise(promise, {
        loading: "Suppresion de l'intervenant...",
        success: (msg) => <b>{msg}</b>,
        error: (msg) => <b>{msg}</b>,
      });
    updateEqTD(newFiche);
  };

  const cancelInTable = () => {
    if (newIntervenant) {
      setNewIntervenant(null);
      setIsEditing(false);
    } else {
      console.log("Je cancel", intervenantEdited);

      const updatedIntervenants = [...intervenants];
      updatedIntervenants[editIndex] = intervenantEdited;
      setIntervenants(updatedIntervenants);
      setEditIndex(null);
      setIsEditing(false);
    }
    setWrongInput(2);
  };

  // Function to calculate the total
  const calculateTotal = (cm, td, tp) => {
    return parseFloat(cm || 0) + parseFloat(td || 0) + parseFloat(tp || 0);
  };

  // Update the newIntervenant state with the calculated total
  const handleInputChange = (field, value) => {
    let intValue = value;
    if (value !== "") {
      intValue = parseFloat(value);
      if (intValue < 0) {
        intValue = 0; // Prevent negative values
      }
    }

    if (newIntervenant) {
      console.log("newIntervenant", newIntervenant);
      //test si le total ne dépasse pas le budjet
      const updatedIntervenant = { ...newIntervenant, [field]: intValue };
      updatedIntervenant.totalEqTD = calculateTotal(
        updatedIntervenant.cm*coefA,
        updatedIntervenant.td*coefB,
        updatedIntervenant.tp*coefC,
      );
      
      setNewIntervenant(updatedIntervenant);
    } else {
      const updatedIntervenants = [...intervenants];
      updatedIntervenants[editIndex][field] = intValue;
      updatedIntervenants[editIndex].totalEqTD = calculateTotal(
        updatedIntervenants[editIndex].cm*coefA,
        updatedIntervenants[editIndex].td*coefB,
        updatedIntervenants[editIndex].tp*coefC,
      );
      //test si le total ne dépasse pas le budjet
      setIntervenants(updatedIntervenants);
    }
  };

  return (
    <div>
      <Toaster />
      <Box
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddIntervenant}
          disabled={isEditing ||  (disableAddInterv && !isDirector)}
        >
          Ajouter une intervention
        </Button>
        <Typography variant="caption" sx={{ color: `${isDateAfterMax ? "red" : "grey" }`}}>
          {`Date limite de dépôt ${isDateAfterMax ? "dépassée " : " "}: ${dateMax.format("DD/MM/YYYY")}`}
        </Typography>
        {newIntervenant && (
          <Box>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Pour ajouter un nouvel intervenant qui n'est pas encore
                    inscrit sur la platforme
                  </Typography>
                </React.Fragment>
              }
              placement="left"
              sx={{ fontSize: "5em" }}
            >
              <Button onClick={openAddIntervenant} sx={{}} color="primary">
                Ajouter un intervenant
              </Button>
              
            </Tooltip>
          </Box>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "left" }}>Nom Prénom</TableCell>
              <TableCell sx={{ textAlign: "center" }}>CM</TableCell>
              <TableCell sx={{ textAlign: "center" }}>TD</TableCell>
              <TableCell sx={{ textAlign: "center" }}>TP</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Total EqTD</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Domaine</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Date de modif.</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newIntervenant && (
              <TableRow>
                <TableCell>
                  <FreeSoloCreateOptionDialog
                    allUsers={allUsers}
                    setNewIntervenant={setNewIntervenant}
                    newIntervenant={newIntervenant}
                    setWrongInput={setWrongInput}
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    value={newIntervenant.cm}
                    onChange={(e) => handleInputChange("cm", e.target.value)}
                    type="number"
                    sx={{ textAlign: "center" }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={newIntervenant.td}
                    onChange={(e) => handleInputChange("td", e.target.value)}
                    type="number"
                    sx={{ textAlign: "center" }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={newIntervenant.tp}
                    onChange={(e) => handleInputChange("tp", e.target.value)}
                    type="number"
                    sx={{ textAlign: "center" }}
                  />
                </TableCell>
                <TableCell>
                  <Typography sx={{ textAlign: "center" }}>
                    {" "}
                    {newIntervenant.totalEqTD.toFixed(2)}{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Select
                      value={newIntervenant.domaine}
                      onChange={(e) => {
                        setWrongInput((wrong) => wrong - 1)
                        setNewIntervenant({...newIntervenant, domaine: e.target.value})}}
                      displayEmpty
                      sx={{ textAlign: "center", minWidth: 120 }}
                  >
                    <MenuItem value="" disabled>
                      <em>Choisissez un domaine</em>
                    </MenuItem>
                    {domaines.map((domain) => (
                        <MenuItem key={domain.idDomaine} value={domain.idDomaine}>
                          {domain.nomDomaine}
                        </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Typography sx={{ textAlign: "center" }}>
                    {" "}
                    {currentDate}{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", flexDirection: "raw" }}>
                    <Tooltip title={tooltipText} placement={"top"}>
                      <span>
                        <IconButton
                          onClick={saveInTable}
                          color="primary"
                          disabled={(totalIsTooHight || wrongInput > 0) && !isDirector}
                        >
                          <SaveIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <IconButton onClick={cancelInTable} color="primary">
                      <CancelIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {intervenants.map((intervenant, index) => (
              <TableRow key={index}>
                <TableCell sx={{ textAlign: "left" }}>
                  {editIndex === index ? (
                    <FreeSoloCreateOptionDialog
                      allUsers={allUsers}
                      setIntervenants={setIntervenants}
                      intervenants={intervenants}
                      editIndex={editIndex}
                      setWrongInput={setWrongInput}
                    />
                  ) : (
                    <Typography>
                      {`${intervenant.nomIntervenant + " " + intervenant.prenomIntervenant}`}{" "}
                    </Typography>
                  )}
                </TableCell>

                <TableCell sx={{ textAlign: "center" }}>
                  {editIndex === index ? (
                    <TextField
                      value={intervenant.cm}
                      type="number"
                      onChange={(e) => handleInputChange("cm", e.target.value)}
                    />
                  ) : (
                    <Typography>{intervenant.cm} </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {editIndex === index ? (
                    <TextField
                      value={intervenant.td}
                      type="number"
                      onChange={(e) => handleInputChange("td", e.target.value)}
                    />
                  ) : (
                    <Typography>{intervenant.td} </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {editIndex === index ? (
                    <TextField
                      value={intervenant.tp}
                      type="number"
                      onChange={(e) => handleInputChange("tp", e.target.value)}
                    />
                  ) : (
                    <Typography>{intervenant.tp} </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                    <Typography>{intervenant.totalEqTD}</Typography>
                </TableCell>
                <TableCell>
                  {editIndex === index ? (
                      <Select
                          value={intervenant.domaine?.id || ""}
                          onChange={(e) => {
                            setWrongInput((wrong) => wrong - 1);
                            setIntervenants((prev) => {
                              const updated = [...prev];
                              updated[index] = { ...updated[index], domaine: { id: e.target.value } };
                              return updated;
                            });
                          }}
                          displayEmpty
                          sx={{ textAlign: "center", minWidth: 120 }}
                      >
                        <MenuItem value="" disabled>
                          <em>Choisissez un domaine</em>
                        </MenuItem>
                        {domaines.map((domain) => (
                            <MenuItem key={domain.idDomaine} value={domain.idDomaine}>
                              {domain.nomDomaine}
                            </MenuItem>
                        ))}
                      </Select>
                      ) : ( 
                  <Typography sx={{ textAlign: "center" }}>
                    {domaines.find((domaine) => domaine.idDomaine === intervenant.domaine?.id)?.nomDomaine}
                  </Typography>
                      )}
                </TableCell>
                <TableCell>
                  <Typography sx={{ textAlign: "center" }}>
                    {" "}
                    {dayjs(intervenant.dateModif).format("DD/MM/YYYY")}
                    {" "}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {editIndex === index ? (
                    <Box sx={{ display: "flex", flexDirection: "raw" }}>
                      <Tooltip title={tooltipText} placement={"top"}>
                        <span>
                          <IconButton
                            onClick={saveInTable}
                            color="primary"
                            disabled={totalIsTooHight && !isDirector}
                          >
                            <SaveIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <IconButton onClick={cancelInTable} color="primary">
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <IconButton
                        onClick={() => handleEditIntervenant(index)}
                        color="primary"
                        disabled={isEditing ||  (disableAddInterv && !isDirector)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteIntervenant(index)}
                        color="primary"
                        disabled={isEditing || (disableAddInterv && !isDirector) }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogAddIntervenant
        roles={roles}
        types={types}
        open={isDialogOpen}
        handleClose={handleClose}
        allUser={allUsers}
        newUserReload={newUserReload}
      />
    </div>
  );
}
